import styled from "@emotion/styled";
import React from "react";
import { Button, LinkButton, Radio } from "@hundred5/design-system";
import { add, format, sub, endOfMonth } from "date-fns";
import {
  ExpertChallenge,
  useAcceptExpertChallengeMutation,
} from "../../utils/graphql-queries";

// This seems redundant, but it'll be useful when we introduce
// bonuses for large batches
const expectedCredits: { [index: string]: number } = {
  "10": 10,
  "20": 20,
  "30": 30,
  "40": 40,
};

const paymentPerCredit = 7;

const longDateFormat = "MMMM d, yyyy";
const timelineDateFormat = "MMM do";
const shortDateFormat = "do MMMM";
interface Props {
  challenge: ExpertChallenge;
  close: () => void;
}

export default function QuestionBatchForm(props: Props) {
  const [numberQuestions, setNumberQuestions] = React.useState(
    props.challenge.requiredQuestions.toString()
  );
  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumberQuestions(event.target.value);
  };
  const [_, acceptExpertChallenge] = useAcceptExpertChallengeMutation();

  const acceptChallenge = async () => {
    await acceptExpertChallenge({ id: props.challenge.id });
    props.close();
  };

  const dates: { [index: string]: Date } = {
    today: new Date(),
  };
  dates.deadline = add(dates.today, { days: props.challenge.deadline });
  dates.review = add(dates.deadline, { days: 7 });
  dates.paymentReleased = endOfMonth(dates.review);
  dates.paymentReceived = add(dates.paymentReleased, { days: 10 });
  dates.earlyDeadline = sub(endOfMonth(dates.today), { days: 7 });
  dates.earlyPayment = add(dates.earlyDeadline, { days: 17 });

  const skillName = props.challenge.expertChallengeSkills[0].skill.name;

  return (
    <GridContainer>
      <Header>
        {numberQuestions} {skillName} Questions
      </Header>
      <SelectorHeader>Number of Questions</SelectorHeader>
      <NumberSelector>
        <RadioLine />
        <RadioGroup>
          <label htmlFor="10">
            <b>10</b>
          </label>
          <OpaqueRadio
            large
            type="radio"
            name="numberQuestions"
            id="10"
            value="10"
            checked={numberQuestions === "10"}
            onChange={onRadioChange}
            disabled={props.challenge.requiredQuestions !== 10}
          />
        </RadioGroup>
        <RadioGroup>
          <label htmlFor="20">
            <b>20</b>
          </label>
          <OpaqueRadio
            large
            type="radio"
            name="numberQuestions"
            id="20"
            value="20"
            checked={numberQuestions === "20"}
            onChange={onRadioChange}
            disabled={props.challenge.requiredQuestions !== 20}
          />
        </RadioGroup>
        <RadioGroup>
          <label htmlFor="30">
            <b>30</b>
          </label>
          <OpaqueRadio
            large
            type="radio"
            name="numberQuestions"
            id="30"
            value="30"
            checked={numberQuestions === "30"}
            onChange={onRadioChange}
            disabled={props.challenge.requiredQuestions !== 30}
          />
          {/* <Bonus minor>+5% Credit</Bonus> */}
        </RadioGroup>
        <RadioGroup>
          <label htmlFor="40">
            <b>40</b>
          </label>
          <OpaqueRadio
            large
            type="radio"
            name="numberQuestions"
            id="40"
            value="40"
            checked={numberQuestions === "40"}
            onChange={onRadioChange}
            disabled={props.challenge.requiredQuestions !== 40}
          />
          {/* <Bonus>+10% Credit</Bonus> */}
        </RadioGroup>
      </NumberSelector>
      <InfoContainer>
        <div>
          <InfoTitle>Skill:</InfoTitle> <b>{skillName}</b>
        </div>
        <div>
          <InfoTitle>Due date:</InfoTitle>{" "}
          <b>{format(dates.deadline, longDateFormat)}</b>
        </div>
      </InfoContainer>
      <Timeline>
        <TimelineDate>
          <b>{format(dates.today, timelineDateFormat)}</b>
        </TimelineDate>
        <TimelineDot active />
        <TimelineDesc>
          <p>Start creating questions</p>
        </TimelineDesc>
        <TimelineDate>
          <b>{format(dates.deadline, timelineDateFormat)}</b>
        </TimelineDate>
        <TimelineDot />
        <TimelineDesc>
          <p>Deadline to submit questions</p>
        </TimelineDesc>
        <TimelineDot />
        <TimelineDesc>
          <b>Peer review &amp; approval</b>
        </TimelineDesc>
        <TimelineDot />
        <TimelineDesc>
          <p>Payment released</p>
        </TimelineDesc>
        <TimelineDot />
        <TimelineDesc>
          <b>Payment received</b>
        </TimelineDesc>
        <TimelineLine />
      </Timeline>
      <Buttons>
        <LinkButton onClick={props.close}>Cancel</LinkButton>
        <Button onClick={acceptChallenge}>Start creating questions</Button>
      </Buttons>
    </GridContainer>
  );
}

const GridContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 65px 20px 20px 105px 150px 80px 20px 1fr 60px;
  row-gap: 10px;
  padding: 50px;
`;

const Header = styled.h1`
  grid-row-start: 1;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
`;

const SelectorHeader = styled.p`
  grid-row-start: 3;
`;

const RadioLine = styled.div`
  position: absolute;
  height: 1px;
  background-color: #e7e7e7;
  width: 100%;
  top: 29px;
  z-index: 0;
`;

const NumberSelector = styled.form`
  grid-row-start: 4;
  position: relative;
  max-width: 280px;
  display: flex;
  justify-content: space-between;
  margin: 15px 0px 45px 0px;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 0 8px;

  & input {
    z-index: 1;
  }
`;

interface BonusProps {
  minor?: boolean;
}
const Bonus = styled.div<BonusProps>`
  background: ${(props) =>
    props.minor ? "rgba(255, 222, 145, 0.1)" : "rgb(255, 222, 145)"};
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 8px;
  max-width: 40px;
  text-align: center;
  padding: 5px;
`;

const OpaqueRadio = styled(Radio)`
  background-color: #ffffff;
  box-shadow: inset 0 0 0 2px #ffffff;

  &[disabled] {
    opacity: 100%;
  }
`;

const InfoContainer = styled.div`
  grid-row-start: 5;
  & p {
    margin: 0.25em 0;
  }
`;

const InfoTitle = styled.div`
  display: inline-block;
  min-width: 160px;
`;

const Footnotes = styled.div`
  grid-row-start: 6;
`;

const Footnote = styled.span`
  color: #ff897a;
`;

const Timeline = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 75px 14px 1fr;
  column-gap: 15px;
  grid-template-rows: repeat(5, 1fr);
  grid-row: 2 / 7;
  grid-column: 2 / 3;
  align-items: center;
  color: #989898;
`;

const TimelineDate = styled.div`
  grid-column-start: 1;
  justify-self: end;
`;

interface TimelineDotProps {
  active?: boolean;
}
const TimelineDot = styled.div<TimelineDotProps>`
  grid-column-start: 2;
  border-radius: 7.5px;
  width: 13px;
  height: 13px;
  border: 2px solid #ff897a;
  background-color: ${(props) => (props.active ? "#FF897A" : "#FFFFFF")};
  position: relative;
  z-index: 1;
`;

const TimelineDesc = styled.div`
  grid-column-start: 3;
`;

const TimelineLine = styled.div`
  grid-column-start: 2;
  position: absolute;
  left: 6px;
  width: 1px;
  background-color: #989898;
  height: 100%;
  z-index: 0;
`;

const ProTip = styled.div`
  grid-row-start: 8;
  grid-column: 1 / 3;
  width: calc(100% - 180px);
  margin: 0 90px;
  background-color: #ffde91;
  padding: 20px 50px;
`;

const Buttons = styled.div`
  grid-row-start: 9;
  grid-column: 2;
  justify-self: end;
  align-self: end;

  & button {
    margin: 0 8px;
  }
`;
